import React from 'react'
import ReactHtmlParser from 'react-html-parser'

const Block = ({ data, tag: Tag = null }) => {
  if (data === null) return null;
  
  return data?.map(parr => {
    return parr?.children.map(child => {
      if (Tag) {
        return <Tag>{ReactHtmlParser(child.text)}</Tag>
      }
      return ReactHtmlParser(child.text)
    })
  })
}

export default Block
